import React, { useState, useEffect } from "react";
import {

    Box,
    Button,
    Typography,
    Stack,
    Card,
} from "@mui/material";
import { Link } from 'react-router-dom';

function AboutPage(props) {
    const { userId, db, showAbout } = props;
    const [showAboutForm, setShowAboutForm] = useState(true)

    const handleClose = () => {
        setShowAboutForm(false);
    };

    function handleLogin() {

    }

    return (

        // (showAbout && showAboutForm) && (
        <div>
            <Card
                elevation={10}
                sx={{
                    width: "70%",
                    // width: "650px",
                    marginTop: "1em",
                    marginBottom: ".5em",
                    marginLeft: "10px",
                    marginRight: "4px",
                }}
            >
                <Stack direction="row">
                    <Typography
                        component={"div"}
                        color="blue"
                        sx={{
                            fontStyle: "regular",
                            fontSize: "13px",
                            textAlign: "justify",
                            paddingLeft: "3px",
                            paddingRight: "15px",
                        }}
                    >
                        <p>
                            <strong>Trupollz </strong>lets anyone create polls and rate
                            anything quickly, honestly and fairly, getting the true picture
                            without being conditioned by Big
                            Media, pollsters, lobbyists etc., who control access
                            and push their own narrative.
                        </p>
                        <p>Use Trupollz for your company - get <strong>real-time</strong> Customer Experience feedback or conduct Anonymous Employee Surveys with <strong>live</strong> results.</p>
                        <p> Or for...anything.</p>
                        <p>
                            To register/login, press Login. After registering, give it a couple of seconds to update the database, then login.
                            Select a button, and hit it again to un-select it. You may need a second or two after a key press for the database to update. A new release will allow polls to be
                            restricted to user-controlled groups (e.g., corporate, family & friends),
                            soon. Also, set your Chrome or other browser Zoom at 75-80% for optimum viewing.
                        </p>
                        <p>For questions/comments/bugs, write to admin@trupollz.com</p>
                    </Typography>
                    {/* <IconButton onClick={handleClose}> */}
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Link to='/'>
                            <Button
                                variant="contained"
                                // backgroundcolor="#333"
                                // endIcon={<SendIcon backgroundColor="grey" />}
                                onClick={handleClose}
                                style={{
                                    border: "none",
                                    width: "100px",
                                    marginLeft: "10px",
                                    marginTop: '4px',
                                    marginRight: '10px',
                                    marginBottom: '8px',
                                    borderRadius: 15,
                                    color: "black",
                                    backgroundColor: "#D1D1D1",
                                }}
                            >
                                Close

                            </Button>
                        </Link>
                    </Box>
                    {/* </IconButton> */}
                </Stack>
            </Card>
        </div>
        // )
    )
}

export default AboutPage