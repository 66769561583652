import React, { useState, useEffect, } from 'react'
import {
    Card,
    Box,
    Typography,
    TextField,
    Grid,
    Stack,
    IconButton,
    Button,
    FormControl,
    Paper,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    Select,
    CardContent,
} from "@mui/material";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import Rating from "@mui/material/Rating";
import { collection, doc, where, query, updateDoc, deleteDoc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore"

import { CriteriaDisplay, RatingDisplay } from "./Ratings";

function DBOpenRateResults(props) {

    const { userId, db } = props;
    const [pollResults, setPollResults] = useState([]);
    const [sort, setSort] = useState({ column: "count", order: "desc" });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedRow, setSelectedRow] = useState(null);
    const [starRating, setStarRating] = React.useState(0);
    const [yesRating, setYesRating] = React.useState(0);
    const [noRating, setnoRating] = React.useState(0);
    const [agreeRating, setAgreeRating] = React.useState(0);
    const [disagreeRating, setDisagreeRating] = React.useState(0);
    const [excellentRating, setExcellentRating] = React.useState(0);
    const [goodRating, setGoodRating] = React.useState(0);
    const [averageRating, setAverageRating] = React.useState(0);
    const [badRating, setBadRating] = React.useState(0);
    const [terribleRating, setTerribleRating] = React.useState(0);
    const [showSelectForm, setShowSelectForm] = React.useState(false);
    const [numVotes, setNumVotes] = React.useState(0);
    const [averageStarRating, setAverageStarRating] = React.useState(0);
    const [ratingResults, setRatingResults] = useState([]);
    const [ratName, setRatName] = useState('');


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (column) => {
        if (sort.column === column) {
            setSort({ column: column, order: sort.order === "asc" ? "desc" : "asc" });
        } else {
            setSort({ column: column, order: "asc" });
        }
    };

    const transformData = (data) => {
        const transformedData = {};

        data.forEach((row) => {
            const { selectedCategory, question, allEvalTargets, expiryDate } = row;

            if (!transformedData[question]) {
                transformedData[question] = [];
            }
            setRatName(question);

            transformedData[question].push({
                selectedCategory, question, allEvalTargets, expiryDate
            });
        });

        return transformedData;
    };
    const fetchData = async () => {
        try {
            const activeTableQuery = query(collection(db, 'ratingTable'), where('status', '==', 'closed'));
            const activeTableSnapshot = await getDocs(activeTableQuery);

            const activeTableData = activeTableSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setRatingResults(transformData(activeTableData))

        } catch (error) {
            if (userId === '') {
                alert("Login first")
            } else {
                console.log("ERROR!")
                console.error("Error fetching data:", error);
            }
        }
    };

    useEffect(() => {
        fetchData();

    }, [averageStarRating, selectedRow, userId, showSelectForm, numVotes, yesRating, noRating, excellentRating, goodRating, badRating, averageRating, terribleRating]);

    const sortedResults = pollResults.sort((a, b) => {
        if (sort.order === "asc") {
            return a[sort.column] > b[sort.column] ? 1 : -1;
        } else {
            return b[sort.column] > a[sort.column] ? 1 : -1;
        }
    });
    const dataToDisplay = sortedResults.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );
    function resetSort(event) {
        setSort({ column: "Results %", order: "desc" });
    }
    function handleButtonClick(result) {
        setSelectedRow(result);
        setShowSelectForm(true);
    }
    function formatDate(dateString) {

        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', hour12: true };
        return new Date(dateString).toLocaleString('en-US', options);
    }

    // let crit, critRO, critROV1, critROV2, critROV3, critROV4, critROV5, critROStarVal;

    const renderCrit = (critData, critNumber, critsOrValues) => {
        const critKey = `crit${critNumber}`;
        const critROKey = `crit${critNumber}RO`;

        if (critData.hasOwnProperty(critKey)) {

            if (critsOrValues === 'crits') {
                return (
                    <div key={critNumber}>
                        {critData[critKey]}
                    </div>
                );
            } else {

                switch (critData[critROKey]) {
                    case 'yesNo':
                        return (
                            <div>

                                😃Yes: {critData[`crit${critNumber}ROV1`]}%<br />
                                😞No:  {critData[`crit${critNumber}ROV2`]}%<br />
                            </div>
                        )
                    case 'agreeDisagree':
                        return (
                            <div>

                                😃Agree: {critData[`crit${critNumber}ROV1`]}%<br />
                                😞Disagree: {critData[`crit${critNumber}ROV2`]}%<br />
                            </div>
                        )
                    case '5Stars':
                        return (
                            <div>

                                <Rating name="result-rating" value={critData[`crit${critNumber}ROStarVal`]} precision={0.5} readOnly />
                            </div>
                        )
                    case 'quality':
                        return (
                            <div>

                                😃Excellent: {critData[`crit${critNumber}ROV1`]}%<br />
                                😞Good: {critData[`crit${critNumber}ROV2`]}%<br />
                                😐Average: {critData[`crit${critNumber}ROV3`]}%<br />
                                😔Bad: {critData[`crit${critNumber}ROV4`]}%<br />
                                😞Terrible: {critData[`crit${critNumber}ROV5`]}%<br />
                            </div>
                        )
                    default: break;
                }
            }
            return null;
        };
    }
    const renderCrits = (critData, critsOrValues) => {
        return [0, 1, 2, 3, 4].map((critNumber) => renderCrit(critData, critNumber, critsOrValues));
    };

    return (
        <Box marginTop={2} marginLeft='10px' >
            <Grid container spacing={1} direction='row'>
                {/* <Grid container spacing={9} direction='row'  > */}
                <Grid item xs={12}  >
                    <Card
                        elevation={10}
                        sx={{ width: "1250px", marginRight: "20px" }}
                    >
                        <TableContainer
                            component={Paper}
                            sx={{ overflow: "auto", marginLeft: '10px', marginTop: "0.5em" }}
                        >
                            <Table size="small" sx={{ width: "100%" }}>
                                <TableHead
                                    sx={{ marginTop: "1em", backgroundColor: "#5B76D7" }}
                                >
                                    <TableRow >
                                        <TableCell
                                            sx={{
                                                whiteSpace: "nowrap",
                                                width: "20%",
                                                fontWeight: "bold",
                                                color: "white",
                                            }}
                                        >
                                            Category
                                            {sort.order === "asc" ? (
                                                <ArrowDropUp
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("selectedCategory")}
                                                />
                                            ) : (
                                                <ArrowDropDown
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("selectedCategory")}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                whiteSpace: "nowrap",
                                                width: "40%",
                                                fontWeight: "bold",
                                                color: "white",
                                            }}
                                        >
                                            Rating Title
                                            {sort.order === "asc" ? (
                                                <ArrowDropUp
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("question")}
                                                />
                                            ) : (
                                                <ArrowDropDown
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("question")}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                whiteSpace: "nowrap",
                                                width: "15%",
                                                fontWeight: "bold",
                                                color: "white",
                                            }}
                                        >
                                            End Date
                                            {sort.order === "asc" ? (
                                                <ArrowDropUp
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("expiryDate")}
                                                />
                                            ) : (
                                                <ArrowDropDown
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("expiryDate")}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                whiteSpace: "pre-wrap",
                                                wordBreak: "break-all",
                                                width: "100%",
                                                fontWeight: "bold",
                                                color: "white",
                                            }}
                                        >
                                            Target
                                            {/* {sort.order === "asc" ? (
                                                <ArrowDropUp
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("Question")}
                                                />
                                            ) : (
                                                <ArrowDropDown
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("Question")}
                                                />
                                            )} */}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                whiteSpace: "pre-wrap",
                                                wordBreak: "break-all",
                                                width: "100%",
                                                fontWeight: "bold",
                                                color: "white",
                                            }}
                                        >
                                            {/* EMPTY but NEEDED !!!!
                                            {sort.order === "asc" ? (
                                                <ArrowDropUp
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("Question")}
                                                />
                                            ) : (
                                                <ArrowDropDown
                                                    sx={{ color: "white" }}
                                                    onClick={() => handleSort("Question")}
                                                />
                                            )} */}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ marginTop: "1em", backgroundColor: "#F7F9F9" }}>
                                    {Object.values(ratingResults).map((resultsArray, index) => (
                                        resultsArray.map((result, resultIndex) => (
                                            <React.Fragment key={resultIndex}>
                                                <TableRow hover>
                                                    <TableCell sx={{ verticalAlign: "top" }}>{result.selectedCategory}</TableCell>
                                                    <TableCell sx={{ verticalAlign: "top" }}>{result.question}</TableCell>
                                                    <TableCell sx={{ verticalAlign: "top" }}>{formatDate(result.expiryDate.toDate())}</TableCell>
                                                    {/* <TableRow> */}
                                                    {Object.keys(result.allEvalTargets).map((target, i) => (
                                                        <React.Fragment key={i}>
                                                            <stack>
                                                                <TableCell sx={{ verticalAlign: "top", minWidth: 70 }}>
                                                                    {target}
                                                                </TableCell>

                                                                <TableCell sx={{ verticalAlign: "top" }}>
                                                                    <TableContainer component={Paper} sx={{ overflow: "auto", display: "flex", flexDirection: "column" }}>

                                                                        <Table size="small" sx={{ minWidth: 350 }}>
                                                                            <TableHead
                                                                                sx={{ backgroundColor: 'efedff' }}
                                                                            >

                                                                                <TableCell sx={{
                                                                                    whiteSpace: "break-word",
                                                                                    width: "50%",
                                                                                    // fontWeight: "bold", 
                                                                                    color: "blue",
                                                                                }}>
                                                                                    Rating Criteria
                                                                                    {sort.order === "asc" ? (
                                                                                        <ArrowDropUp sx={{ color: "white" }} onClick={() => handleSort("Results %")} />) : (
                                                                                        <ArrowDropDown sx={{ color: "white" }} onClick={() => handleSort("Results %")} />
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="justify"
                                                                                    sx={{
                                                                                        width: "50%",
                                                                                        whiteSpace: "nowrap",
                                                                                        // fontWeight: "bold",
                                                                                        color: "blue"
                                                                                    }}
                                                                                >
                                                                                    Rating
                                                                                    {sort.order === "asc" ? (
                                                                                        <ArrowDropUp
                                                                                            sx={{ color: "white" }}
                                                                                            onClick={() => handleSort("# Voters")}
                                                                                        />
                                                                                    ) : (
                                                                                        <ArrowDropDown
                                                                                            sx={{ color: "white" }}
                                                                                            onClick={() => handleSort("# Voters")}
                                                                                        />
                                                                                    )}
                                                                                </TableCell>
                                                                            </TableHead>
                                                                            <br />
                                                                            <TableBody>

                                                                                {Object.keys(result.allEvalTargets[target]).map((critIndex, j) => {
                                                                                    const crit = result.allEvalTargets[target][critIndex];
                                                                                    return (
                                                                                        < TableRow key={j} >
                                                                                            <TableCell>
                                                                                                {renderCrits(crit, 'crits')}
                                                                                            </TableCell>
                                                                                            <TableCell>
                                                                                                {renderCrits(crit, 'values')}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })}

                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </TableCell>
                                                            </stack>

                                                        </React.Fragment>
                                                    ))}
                                                    {/* </TableRow> */}
                                                </TableRow>
                                            </React.Fragment>
                                        ))
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component="div"
                                count={sortedResults.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[]}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Card>
                </Grid>
            </Grid>

        </Box >
    )
}

export default DBOpenRateResults