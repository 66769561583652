import React, { useState, useEffect } from "react";

import {
  Box,
  Typography,
  TextField,
  Stack,
  Autocomplete,
} from "@mui/material";

import {
  questionCategory,
  ratingOptions,
} from "./MyData";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { collection, where, query, addDoc, getDocs } from "firebase/firestore"

function NewPoll(props) {
  const { userId, db } = props;
  // const [userId, setUserId] = useState(props.userId);

  const [question, setQuestion] = React.useState("");
  const [binaryRespQuestFlag, setBinaryRespQuestFlag] = React.useState(false)
  const [pollResults, setPollResults] = useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedRatingOption, setSelectedRatingOption] = React.useState(null);
  const [shownSelectedRatingOption, setShownSelectedRatingOption] =
    React.useState(null);
  const [optionA, setOptionA] = React.useState("");
  const [optionB, setOptionB] = React.useState("")

  const [questCategorySelected, setQuestCategorySelected] = React.useState(false);
  const [expiryDate, setExpiryDate] = useState(new Date());

  const handleExpiryDateChange = (datePicked) => {
    let date = datePicked.toDate();
    console.log("date:", date)
    setExpiryDate(date);
  };

  // const handleExpiryDateChange = (date) => {
  //   const expiryString = date.toISOString();
  //   setExpiryDate(expiryString);
  // };

  const filterResults = (results) => {
    return results.filter((row) => row.userId === props.userId);
  };

  useEffect(() => {

  }, [selectedCategory, expiryDate]);

  async function addEntryToTable(collectionName, data) {
    console.log("data:", data)
    try {
      const docRef = await addDoc(collection(db, collectionName), data);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    let quest = question;

    if (selectedRatingOption === 'AorB') {
      quest = optionA + "   or   " + optionB + "?"
    }
    // const expiryDate = new Date();
    const pollTableData = {
      userId: userId,
      question: quest,
      optionA,
      optionB,
      selectedCategory: selectedCategory,
      selectedRatingOption: selectedRatingOption,
      expiryDate: expiryDate,
      optionARating: 0,
      optionBRating: 0,
      yesRating: 0,
      noRating: 0,
      agreeRating: 0,
      disagreeRating: 0,
      numVotes: 0,
      excellentRating: 0,
      goodRating: 0,
      averageRating: 0,
      badRating: 0,
      terribleRating: 0,
      averageStarRating: 0,
      status: 'open',
      type: 'poll'
    };

    addEntryToTable('pollTable', pollTableData);

    const pollTableQuery = query(collection(db, 'pollTable'), where('question', '==', quest));
    const activePollTableSnapshot = await getDocs(pollTableQuery);
    const pollTableId = activePollTableSnapshot.docs[0].id;
    const pendingVotesTableData = {
      pendingVotesTableId: pollTableId,
      userId: userId
    }

    addEntryToTable('pendingVotesTable', pendingVotesTableData);
    setQuestCategorySelected(false);
    setSelectedCategory("");
    setShownSelectedRatingOption("");
    setBinaryRespQuestFlag(false)
  };

  function handleCategoryChange(event, value) {
    setSelectedCategory(value);
  }

  function handleRatingTypeChange(event, value) {
    switch (value) {
      case "❓A or B":
        setSelectedRatingOption('AorB');
        setShownSelectedRatingOption("❓A or B");
        setBinaryRespQuestFlag(true);
        break;
      case "✔️Yes, ❌No":
        setSelectedRatingOption("yesNo");
        setShownSelectedRatingOption("✔️Yes, ❌No");
        setQuestCategorySelected(true);
        break;
      case "✔️Agree, ❌Disagree":
        setSelectedRatingOption("agreeDisagree");
        setShownSelectedRatingOption("✔️Agree, ❌Disagree");
        setQuestCategorySelected(true);
        break;
      case "⭐️ ... ⭐️⭐️⭐️⭐️⭐️":
        setSelectedRatingOption("5Stars");
        setShownSelectedRatingOption("⭐️ ... ⭐️⭐️⭐️⭐️⭐️");
        setQuestCategorySelected(true);
        break;
      case "😞Terrible, 😔Bad, 😐Average, 🙂Good, 😃Excellent":
        setSelectedRatingOption("quality");
        setShownSelectedRatingOption(
          "😞Terrible, 😔Bad, 😐Average, 🙂Good, 😃Excellent"
        );
        setQuestCategorySelected(true);
        break;
      case "🐢Very Slow, 🐇Slow, 🦊Average, 🐆Fast, 🦅Very Fast":
        setSelectedRatingOption("responsiveness");
        setShownSelectedRatingOption(
          "🐢Very Slow, 🐇Slow, 🦊Average, 🐆Fast, 🦅Very Fast"
        );
        setQuestCategorySelected(true);
        break;
      default:
        console.log("SWERR!");
    }
  }
  function getBinaryOptions() {
    return (

      <div style={{ display: "flex", alignItems: "left" }}>
        < TextField
          label="Enter opt. A, e.g., Coke"
          style={{
            fontWeight: "bold",
            marginTop: "6px",
            marginBottom: "6px",
            width: "174px",
            color: 'red'
          }}
          textAlign="justify"
          marginTop="1em"
          inputProps={{
            style: {
              color: 'blue'
            }
          }}

          variant="outlined"
          // multiline
          value={optionA}
          onChange={(event) => {
            setOptionA(event.target.value);
          }}
        />

        {optionA && (
          <>
            <span style={{ color: 'blue' }}>
              {"\u00A0\u00A0\u00A0"} or {"\u00A0\u00A0\u00A0"}
            </span>
            < TextField
              label="Enter opt. B, e.g., Pepsi"
              style={{
                fontWeight: "bold",
                marginTop: "6px",
                marginBottom: "6px",
                width: "174px",
                color: 'red'
              }}
              textAlign="justify"
              marginTop="1em"
              inputProps={{
                style: {
                  color: 'blue'
                }
              }}
              variant="outlined"
              // multiline
              value={optionB}
              onChange={(event) => {
                setOptionB(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSubmit(event);
                }
              }}
            />
          </>
        )}
      </div>
    )
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box marginTop={3} marginLeft="4px">
          <Stack
            spacing={2}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Autocomplete
              value={selectedCategory}
              variant="filled"
              style={{
                marginLeft: "3px",
                marginTop: "1em",
                marginBottom: "0.5em",
                width: 450,
              }}
              color="default"
              options={questionCategory}
              onChange={handleCategoryChange}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label="Poll Category" variant="filled" />
              )}
            />
            <Autocomplete
              value={shownSelectedRatingOption}
              variant="filled"
              style={{ marginLeft: "3px", width: 450 }}
              color="default"
              options={selectedCategory ? ratingOptions : []}
              onChange={handleRatingTypeChange}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Polling Options"
                  variant="filled"
                  helperText={
                    <Typography
                      style={{
                        fontSize: 10,
                        fontStyle: "italic",
                        textAlign: "left",
                      }}
                    >
                      * Select category, then options
                    </Typography>
                  }
                />
              )}
            />
            <DatePicker
              label="Closing Date"
              onChange={(value) => handleExpiryDateChange(value)}
              sx={{ width: "450px" }}
              clearText="Clear"
              cancelText="Cancel"
              todayText="Today"
              showTodayButton
            />

            {binaryRespQuestFlag ?
              getBinaryOptions()
              : (
                questCategorySelected && (
                  <TextField
                    label="Enter your poll question, and press the 'Enter' key:"
                    style={{
                      fontWeight: "bold",
                      marginTop: "6px",
                      marginBottom: "6px",
                      width: "768px",
                    }}
                    textAlign="justify"
                    marginTop="1em"
                    inputProps={{ maxlength: 200, wrap: "hard" }}
                    variant="outlined"
                    multiline
                    value={question}
                    onChange={(event) => {
                      setQuestion(event.target.value);
                    }}
                    helperText={`${question ? question.length : 0}/200`}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        handleSubmit(event);
                      }
                    }}
                  />
                )
              )}
          </Stack>
        </Box>
      </LocalizationProvider>
    </>
  );
}
export default NewPoll;
