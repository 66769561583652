import React, { useState, useEffect } from "react";
import {
    Alert,
    Box,
    TextField,
    Typography,
    Stack,
    Button,
    FormControlLabel, Checkbox
} from "@mui/material";
import styled from "@emotion/styled";
import TnC from './TnC';
import { auth, db } from "../config/firebase.js";
import { Link } from "react-router-dom"

import {
    // getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile,
    // AuthCredential,
} from "firebase/auth";
import { getFirestore, where, query, doc, setDoc, collection, getDocs, addDoc } from "firebase/firestore";
// import {where, query, updateDoc, deleteDoc, setDoc } from "firebase/firestore"

function Login({ onLogin, userId }) {
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [loginUserId, setLoginUserId] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [registrationError, setRegistrationError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    const [tnCDialogOpen, setTnCDialogOpen] = useState(false);
    const [tnCAccepted, setTnCAccepted] = useState(false);

    const button1Color = "#5B76D7";
    const button2Color = "#f5f5f5";
    const buttonSelectedColor = "#af52bf";
    const [selectedButton, setSelectedButton] = useState(null);

    const useStyles = styled((theme) => ({
        selected: { backgroundColor: buttonSelectedColor, color: "white" },
        notSelected: { backgroundColor: button2Color, color: "grey" },
    }));

    // const handleCancel = () => {
    //   onLoginCancel();
    // };

    const handleTnCDialogOpen = () => {
        setTnCDialogOpen(true);
    };

    const handleTnCDialogClose = () => {
        setTnCDialogOpen(false);
    };

    const handleCheckboxChange = (event) => {
        setTnCAccepted(event.target.checked);
    };

    useEffect(() => { }, [
        userId,
        loginUserId,
        email,
        loginPassword,
        loginError,
        registrationError,
        loggedIn,
    ]);

    async function addEntryToTable(collectionName, userId, email) {
        try {
            const docRef = await addDoc(collection(db, collectionName), userId, email);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    const handleLogin = async () => {

        if (!(email && loginPassword)) {
            console.log("No email and/or loginPassword");
            return setLoginError(true);
        }
        setLoginError(false);

        try {

            const userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                loginPassword
            );
            const user = userCredential.user; // Get the user object
            const displayName = user.displayName;
            setLoginUserId(displayName);
            userId = displayName;
            setLoggedIn(true);
            onLogin(displayName);
            setEmail("");
            setLoginPassword("");
            setShowLogin(false);
            setLoginError(false);
        } catch (error) {
            console.log("Login error:", error);
            setLoginError(true);
        }

    };

    const isDisplayNameUnique = async (displayName) => {
        try {
            const q = query(collection(db, "TPUsers"), where("displayName", "==", displayName));
            const querySnapshot = await getDocs(q);
            return querySnapshot.empty;
        } catch (error) {
            console.error("Error checking display name uniqueness:", error);
            return false;
        }
    };

    const handleRegister = async () => {
        if (!(name && email && loginUserId && loginPassword)) {
            console.log("Need all Registration Info");
            setShowRegister(true);
            return setRegistrationError(true);
        } else {
            const isUnique = await isDisplayNameUnique(loginUserId);
            if (!isUnique) {
                console.log("User Id taken, please use other");
                setShowRegister(true);
                return setRegistrationError(true);
            }
            // addEntryToTable("TPUsers", email)

            // addEntryToTable("TPUsers", name, loginUserId, email)
            console.log("Got all info:", name, email, loginUserId, loginPassword);
            userId = loginUserId;
            setRegistrationError(false);
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    loginPassword
                );
                await updateProfile(userCredential.user, {
                    displayName: loginUserId,
                });
                console.log("User registered successfully:", userCredential.user.displayName);

            } catch (error) {
                console.log("Registration error:", error.message);
                setShowRegister(true);
                return setRegistrationError(true);
            }

            setShowRegister(false);
        }
    };

    const handleLoginClick = () => {
        setShowLogin(true);
        setShowRegister(false);
        setLoginError(false);
        setSelectedButton(selectedButton === 1 ? null : 1);
    };

    const handleRegisterClick = () => {
        setShowLogin(false);
        setShowRegister(true);
        setRegistrationError(false);
        setSelectedButton(selectedButton === 2 ? null : 2);
    };

    const handleNameChange = (event) => {
        if (event.key === "Enter") {
            console.log("name:", name);
            setName(event.target.value);
        }
    };
    const handleEmailChange = (event) => {
        if (event.key === "Enter") {
            console.log("email:", email);
            setEmail(event.target.value);
        }
    };
    const handleUserIdChange = (event) => {
        if (event.key === "Enter") {
            console.log("userid:", loginUserId);
            setLoginUserId(event.target.value);
        }
    };
    const handlePasswordChange = (event) => {
        if (event.key === "Enter") {
            setLoginPassword(event.target.value);
        }
    };

    return (
        <>
            < Box >
                <Stack marginTop="0.5em" marginBottom="0.5em" direction="row" spacing={1}>
                    <Button
                        variant="outlined"
                        // endIcon={<SendIcon backgroundColor="grey" />}
                        onClick={handleLoginClick}
                        style={{
                            border: "none",
                            width: "150px",
                            height: "60px",
                            marginLeft: "5px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            backgroundColor:
                                selectedButton === 1 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 1 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            // backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        LOGIN
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        // endIcon={<SendIcon backgroundColor="grey" />}
                        onClick={handleRegisterClick}
                        style={{
                            border: "none",
                            width: "150px",
                            height: "60px",
                            // marginLeft: "2px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            backgroundColor:
                                selectedButton === 2 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 2 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            zIndex: 1,
                        }}
                    >
                        REGISTER
                    </Button>
                </Stack>
                {
                    showLogin ? (
                        <div>
                            <TextField
                                sx={{ marginBottom: ".25em", marginLeft: "8px" }}
                                label="Email Address"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyPress={handleEmailChange}
                            />
                            <TextField
                                label="Password"
                                variant="outlined"
                                type="password"
                                sx={{ marginBottom: "0.5em", marginLeft: "8px" }}
                                value={loginPassword}
                                onChange={(e) => setLoginPassword(e.target.value)}
                                onKeyPress={handlePasswordChange}
                            />
                            <Link to='/'>
                                <Button
                                    variant="contained"
                                    backgroundcolor="#333"
                                    // endIcon={<SendIcon backgroundColor="grey" />}
                                    style={{
                                        border: "none",
                                        width: "100px",
                                        marginLeft: "6px",
                                        // marginTop: "0.25em",
                                        marginBottom: "0.5em",
                                        borderRadius: 28,
                                        color: "black",
                                        backgroundColor: "#D1D1D1",
                                    }}
                                    onClick={handleLogin}
                                >
                                    Save
                                </Button>
                            </Link>
                        </div>
                    ) : null
                }

                {
                    showRegister ? (
                        <div>
                            <TextField
                                label="Name"
                                variant="outlined"
                                sx={{ marginBottom: ".25em", marginLeft: "8px" }}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onKeyPress={handleNameChange}
                            />
                            <TextField
                                label="Email"
                                variant="outlined"
                                sx={{ marginBottom: ".25em", marginLeft: "8px" }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyPress={handleEmailChange}
                            />
                            <TextField
                                label="Enter a userid"
                                variant="outlined"
                                sx={{ marginBottom: ".25em", marginLeft: "8px" }}
                                value={loginUserId}
                                onChange={(e) => setLoginUserId(e.target.value)}
                                onKeyPress={handleUserIdChange}
                                inputProps={{ maxLength: 8 }}
                                helperText={`${loginUserId ? loginUserId.length : 0}/8`}
                            />
                            <TextField
                                label="Password"
                                variant="outlined"
                                sx={{ marginBottom: "0.5em", marginLeft: "8px" }}
                                value={loginPassword}
                                onChange={(e) => setLoginPassword(e.target.value)}
                                onKeyPress={handlePasswordChange}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={tnCAccepted} onChange={handleCheckboxChange} />}
                                label="I accept the terms and conditions"
                                onClick={handleTnCDialogOpen}
                            />

                            <Button
                                variant="contained"
                                backgroundcolor="#333"
                                // endIcon={<SendIcon backgroundColor="grey" />}
                                style={{
                                    border: "none",
                                    width: "100px",
                                    marginLeft: "6px",
                                    // marginTop: "0.25em",
                                    marginBottom: "0.5em",
                                    borderRadius: 28,
                                    color: "black",
                                    backgroundColor: "#D1D1D1",
                                }}
                                onClick={handleRegister}
                            >
                                Save
                            </Button>

                            <TnC open={tnCDialogOpen} handleClose={handleTnCDialogClose} />

                        </div>
                    ) : null
                }

                {
                    showLogin && loginError && (
                        <Alert severity="error">
                            Login failure...check userId/password or contact us
                        </Alert>
                    )
                }
                {
                    showRegister && registrationError && (
                        <Alert severity="error">
                            Registration failure...ensure all fields filled, or try another userId
                        </Alert>
                    )
                }
            </Box >
        </>
    );
}

export default Login;
