import React, { useState, useEffect } from "react";
import {
    Alert,
    Card,
    TextField,
    Stack,
    Button,
    Box
} from "@mui/material";
import { auth, db } from "../config/firebase.js";
import { collection, doc, where, query, updateDoc, deleteDoc, getDoc, getDocs, setDoc, addDoc } from "firebase/firestore";
import Login from "./Login.js";
import ProCorp from "./ProCorpCX.js";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function ProCorpPrivate({ onLogin, userId }) {
    const [companyId, setCompanyId] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [companyIdError, setCompanyIdError] = useState(false)
    const [showEnterCompanyId, setShowEnterCompanyId] = useState(true);
    const [correctCompanyIdEntered, setCorrectCompanyIdEntered] = useState(false);

    const button1Color = "#5B76D7";
    const button2Color = "#f5f5f5";
    const buttonSelectedColor = "#af52bf";
    const [selectedButton, setSelectedButton] = useState();

    useEffect(() => { }, [
        userId,
        companyId,
        correctCompanyIdEntered,
        showEnterCompanyId
    ]);

    const checkIfCompanyIdCorrect = async (companyId) => {
        try {
            const docRef = doc(db, "CorpPolls", companyId);
            const docSnapshot = await getDoc(docRef);

            if (!docSnapshot.exists()) {
                console.log("Failed...", companyId, docSnapshot.id)
                return false;
            }
            return true;
        } catch (error) {
            console.error("Error checking document:", error);
            return false;
        }
    }

    const handleCompanyIdEntered = async () => {
        const isCompanyIdCorrect = await checkIfCompanyIdCorrect(companyId)

        if (!isCompanyIdCorrect) {
            setCompanyId('');
            return setCompanyIdError(true);
        }

        setCompanyId('');
        setShowEnterCompanyId(false)
        setCompanyIdError(false);
        setSelectedButton(!selectedButton);
        setCorrectCompanyIdEntered(true)
    };

    // const handlePrivateClicked = () => {
    //     setShowEnterCompanyId(!showEnterCompanyId)
    //     setCompanyIdError(false);
    //     setSelectedButton(!selectedButton);
    //     setSelectedButton(selectedButton === 1 ? null : 1);
    // };

    const handleClose = () => {
        setCompanyIdError(false);
    };

    return (
        <Box>
            {showEnterCompanyId ? (
                <div>
                    <Card
                        elevation={10}
                        sx={{
                            width: "225px",
                            marginTop: "1em",
                            marginBottom: ".25em",
                            marginLeft: "8px",
                            marginRight: "4px",
                        }}
                    >
                        <TextField
                            label="Company ID"
                            variant="outlined"
                            sx={{ marginLeft: '10px', marginRight: '10px', marginTop: '5px' }}
                            value={companyId}
                            onChange={(e) => setCompanyId(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            backgroundcolor="#333"
                            // endIcon={<SendIcon backgroundColor="grey" />}
                            style={{
                                border: "none",
                                width: "100px",
                                marginLeft: "10px",
                                marginRight: "10px",
                                marginTop: "5px",
                                marginBottom: "5px",
                                borderRadius: 28,
                                color: "black",
                                backgroundColor: "#D1D1D1",
                            }}
                            onClick={handleCompanyIdEntered}
                        >
                            Save
                        </Button>
                    </Card>
                </div>
            ) : null
            }
            {correctCompanyIdEntered && (
                // <Tester onLogin={onLogin} userId={userId} />
                <Login onLogin={onLogin} userId={userId} />
            )}

            {showEnterCompanyId && companyIdError && (

                <Alert severity="error" action={
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                }>
                    <span style={{ fontStyle: 'italic' }}>Enter the correct Company ID, or contact your adminstrator</span>
                </Alert>
            )}
        </Box >
    )
}

export default ProCorpPrivate;
