import React, { useState } from "react";
import {
    Stack,
    Button,
    Tooltip
} from "@mui/material";

import NewPoll from "./NewPoll.js";
import NewRating from "./NewRating.js";

// const button1Color = "#5B76D7";
const button2Color = "#f5f5f5";
const buttonSelectedColor = "#af52bf";

function NewPollsandRatings(props) {
    const { userId, db } = props;
    // const [userId, setUserId] = useState(props.userId.userId);
    const [selectedButton, setSelectedButton] = useState(1);

    const handleButtonClick = (index) => {
        if (selectedButton === index) {
            setSelectedButton(null);
        } else {
            setSelectedButton(index);
        }
    };

    return (
      <React.Fragment>
        <Stack
          spacing={2}
          direction="row"
          marginLeft={1.1}
          marginTop={4}
          marginBottom={1}
        >
          <Tooltip title="e.g., Vote again for Biden?" placement="top">
          <Button
            variant="contained"
            onClick={() => handleButtonClick(1)}
            style={{
              border: "none",
              width: "100px",
              height: "100px",
              fontFamily: "'Open Sans', sans-serif",
              backgroundColor:
                selectedButton === 1 ? buttonSelectedColor : button2Color,
              color: selectedButton === 1 ? "white" : "grey",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              boxShadow: "80%",
              textTransform: "none",
              zIndex: 2,
            }}
          >
            POLL
          </Button>
          </Tooltip>

          <Tooltip title="e.g., Biden or Trump?" placement="top">
            <Button
            variant="contained"
            onClick={() => handleButtonClick(2)}
            style={{
              border: "none",
              width: "100px",
              height: "100px",
              fontFamily: "'Open Sans', sans-serif",
              backgroundColor:
                selectedButton === 2 ? buttonSelectedColor : button2Color,
              color: selectedButton === 2 ? "white" : "grey",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              boxShadow: "80%",
              textTransform: "none",
              zIndex: 3,
            }}
          >
            RATE
          </Button>
          </Tooltip>
        </Stack>
        {selectedButton === 1 && <NewPoll userId={userId} db={db} />}
        {selectedButton === 2 && <NewRating userId={userId} db={db} />}
      </React.Fragment>
    );
}
export default NewPollsandRatings;
