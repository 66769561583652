import "./App.css";
import { useState, useEffect } from "react";
// import Pro from "./components/Pro";
import TopAppBar from "./components/TopAppBar.js";
import Options from "./components/Options.js";
// import Ratings from "./components/Ratings";
// import OwnPoll from "./components/OwnPoll.js";
// import OwnEvals from "./components/OwnEvals";
import Login from "./components/Login.js";
import { auth, db } from "./config/firebase.js";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPage from './components/AboutPage.js';
import LoginPage from './components/LoginPage.js'
import CorpPage from './components/CorpPage.js'

function App() {
  const [userId, setUserId] = useState("");
  const [company, setCompany] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  function handleLogin(newUserId) {
    // console.log("App.js userId, company:", newUserId, company)
    setUserId(newUserId);
    setCompany(company)
    setLoggedIn(true);
  }

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUserId("");
      setCompany("")
      setLoggedIn(false);
      console.log("User logged out successfully");
      <Options userId={userId} db={db} onLogin={handleLogin} />
    } catch (error) {
      console.error("Error logging out:", error);
    }
    setLoggedIn(false);
  };
  // 
  useEffect(() => {
  }, [loggedIn, userId]);

  return (
    <Router>
      <div className="App">
        <TopAppBar
          onLogin={handleLogin}
          onLogout={handleLogout}
          userId={userId}
        />
        <Routes>
          <Route path="/" element={<Options userId={userId} db={db} onLogin={handleLogin} onLogout={handleLogout} />} />
          <Route path="/login" element={<LoginPage userId={userId} onLogin={handleLogin} onLogout={handleLogout} />} />
          <Route path="/corp" element={<CorpPage userId={userId} onLogin={handleLogin} onLogout={handleLogout} />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>

        {/* <Options userId={userId} db={db} /> */}
        {/* </TopAppBar> */}

      </div>
    </Router>

  );
}
export default App;
