import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

//prov3baas project
// const firebaseConfig = {
//   apiKey: "AIzaSyBw9hAP2Wf3n5cxfAbgX0WDkFFL8LF9onY",
//   authDomain: "prov3baas.firebaseapp.com",
//   projectId: "prov3baas",
//   storageBucket: "prov3baas.appspot.com",
//   messagingSenderId: "30605796701",
//   appId: "1:30605796701:web:54c550fb45d9a653deb4b4"
// };

const firebaseConfig = {
  apiKey: "AIzaSyChFcdiBUjGyUYb95pfxT-eKwvTIIOr9M4",
  authDomain: "prov3firebase1.firebaseapp.com",
  projectId: "prov3firebase1",
  storageBucket: "prov3firebase1.appspot.com",
  messagingSenderId: "839087193236",
  appId: "1:839087193236:web:4dfe8b1a58d452ce6f0881"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
// export const googleProvider = new GoogleAuthProvider();
//export const storage = getStorage(app);
