import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, FormControlLabel, Checkbox } from '@mui/material';

const TermsAndConditions = ({ open, handleClose }) => {

    const termsText = `
By registering for and participating in www.trupollz.com, you agree to the following terms and conditions:

1. Content Responsibility: Users are solely responsible for the content they post. Trupollz Solutions Pvt. Ltd. ("Trupollz") does not endorse or take responsibility for any user-generated content.
2. Accuracy of Information: Trupollz does not guarantee the accuracy, completeness, or usefulness of any information posted by users. Reliance on any information provided is at your own risk.
3. Third-party Links: www.trupollz.com may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.
4. Modification of Terms: Trupollz.com reserves the right to modify these terms at any time. It is your responsibility to review these terms periodically for changes.
5. Indemnity: You agree to indemnify and hold harmless Trupollz, its affiliates, and their respective directors, officers, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your use of www.trupollz.com or any violation of these terms.
6. Code of Conduct: All users of www.trupollz.com are expected to adhere to the following code of conduct:
6a) Respectful Behavior: Treat all members with respect. Do not engage in personal attacks, harassment, hate speech, or discrimination based on race, gender, religion, nationality, disability, sexual orientation, or age.
6b) Content Guidelines: Do not post or share any content that is defamatory, obscene, illegal, or infringes on the rights of others (including intellectual property rights).
6c) Privacy: Respect the privacy of others. Do not share personal information about others without their consent.
6d) Compliance: Follow all applicable laws and regulations. Do not engage in any activity that violates local, state, national, or international laws.
6e) Accountability: Take responsibility for your actions. Report any violations of this code of conduct to admin@trupollz.com.
7) Violations of any of the above will cause your subscription to be cancelled and the appropriate authorities notified as needed at the discretion of Trupollz.
  `;

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Terms and Conditions</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {termsText}
                </DialogContentText>
                {/* <FormControlLabel
                    control={<Checkbox />}
                    label="I accept the terms and conditions"
                /> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TermsAndConditions;
