import React, { useState, useEffect } from "react";

import {
  Card,
  Box,
  Typography,
  TextField,
  Grid,
  Stack,
  Autocomplete,
  Button,
} from "@mui/material";
import {
  questionCategory,
} from "./MyData";

import { collection, where, query, getDocs, addDoc } from "firebase/firestore";
import { ratingOptions, supportedRatingOptions } from "./MyData";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { SignalCellularNullOutlined } from "@mui/icons-material";

function NewRating(props) {
  const { userId, db } = props;
  // const [userId, setUserId] = useState(props.userId);

  const [pollResults, setPollResults] = useState([]);
  const [selectedRatingOption, setSelectedRatingOption] = React.useState(null);
  const [shownSelectedRatingOption, setShownSelectedRatingOption] =
    React.useState(null);
  const [sort, setSort] = useState({ column: "count", order: "desc" });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showTargetEntry, setShowTargetEntry] = React.useState(false);
  const [showCriteriaEntry, setShowCriteriaEntry] = React.useState(false);
  const [showRatingsEntry, setShowRatingsEntry] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const [evalTitle, setEvalTitle] = useState("");
  const [evalTargetName, setEvalTargetName] = useState("");

  const [allEvalTargets, setAllEvalTargets] = useState([]);
  const [evalCriteriaName, setEvalCriteriaName] = React.useState("");
  const [allEvalCriteria, setAllEvalCriteria] = React.useState([]);
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleEvalTitleChange = (event) => {
    if (event.key === "Enter") {
      setEvalTitle(event.target.value);
      // setIsButtonDisabled(true);
      // setShowCriteriaEntry(true);
    }
  };

  // const disableTitleChange = (event) => {
  //     setIsButtonDisabled(true);
  //     setShowCriteriaEntry(true);
  // };

  const handleAddTarget = () => {
    setAllEvalTargets([...allEvalTargets, evalTargetName]);

    setEvalTargetName("");
  };

  function handleCategoryChange(event, value) {
    setSelectedCategory(value);
  }

  const handleAddCriteria = () => {
    setAllEvalCriteria([
      ...allEvalCriteria,
      {
        evalCriteriaName: evalCriteriaName,
        selectedRatingOption: selectedRatingOption,
      },
    ]);
    //  console .log("Criteria:", allEvalCriteria);
    setEvalCriteriaName("");
    setSelectedRatingOption(null);
  };
  const handleAddCriteriaDone = () => {
    setEvalCriteriaName("");
    setSelectedRatingOption(null);
    setShowCriteriaEntry(false);
    setShowTargetEntry(true);
  };
  const handleEvalCriteriaNameChange = (event) => {
    if (event.key === "Enter") {
      setEvalCriteriaName(event.target.value);
    }
  };
  const handleEvalTargetNameChange = (event) => {
    if (event.key === "Enter") {
      setEvalTargetName(event.target.value);
    }
  };

  const handleExpiryDateChange = (date) => {
    setExpiryDate(date);
    setIsButtonDisabled(true);
    setShowCriteriaEntry(true);
  };
  const filterResults = (results) => {
    return results.filter((row) => row.userId === props.userId);
  };
  const updateResultsDisplay = (results) => {
    setPollResults(filterResults(results));
  };
  useEffect(() => {
  }, [
    expiryDate,
    evalTitle,
    evalCriteriaName,
    evalTargetName,
    allEvalCriteria,
    allEvalTargets,
  ]);

  const [activePollResults, setShowActivePollResults] = React.useState(false);
  const [closedPollResults, setShowClosedPollResults] = React.useState(false);

  const handleActivePollResults = () => {
    setShowActivePollResults(!activePollResults);
  };
  // const handleClosedPollResults = () => {axios
  //   setShowClosedPollResults(!closedPollResults);
  // };

  async function addEntryToTable(collectionName, data) {
    try {
      const docRef = await addDoc(collection(db, collectionName), data);
      return docRef;
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e;
    }
  }
  const handleSubmit = async (event) => {
    try {

      const allEvalTargetsObject = {};
      allEvalTargets.forEach((target) => {

        allEvalTargetsObject[target] = allEvalCriteria.map((criteria, index) => ({
          [`crit${index}`]: criteria.evalCriteriaName,
          [`crit${index}RO`]: criteria.selectedRatingOption,
          [`crit${index}ROV1`]: null,
          [`crit${index}ROV2`]: null,
          [`crit${index}ROV3`]: null,
          [`crit${index}ROV4`]: null,
          [`crit${index}ROV5`]: null,
          [`crit${index}ROStarVal`]: null,
          [`crit${index}NumVotes`]: 0
        }));
      });

      const expiryDate = new Date();
      const ratingTableData = {
        userId: userId,
        selectedCategory: selectedCategory,
        question: evalTitle,
        expiryDate: expiryDate,
        status: "open",
        type: "rating",
        allEvalTargets: allEvalTargetsObject
      };
      const ratingTableRef = await addEntryToTable(
        "ratingTable",
        ratingTableData
      );

      const ratingTableId = ratingTableRef.id;

      const pendingVotesTableData = {
        pendingVotesTableId: ratingTableId,
        userId: userId,
      };

      await addEntryToTable("pendingVotesTable", pendingVotesTableData);
    } catch (error) {
      console.error("Error:", error);

    }
    setShowRatingsEntry(false);
    setSelectedRatingOption(null);
    setShowCriteriaEntry(false);
    setShowTargetEntry(false);
    setIsButtonDisabled(false);
    setEvalTargetName("");
    setShownSelectedRatingOption("");
  };

  const sortedResults = pollResults.sort((a, b) => {
    if (sort.order === "asc") {
      return a[sort.column] > b[sort.column] ? 1 : -1;
    } else {
      return b[sort.column] > a[sort.column] ? 1 : -1;
    }
  });
  const dataToDisplay = sortedResults.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  function handleRatingTypeChange(event, value) {
    switch (value) {
      case "✔️Yes, ❌No":
        setSelectedRatingOption("yesNo");
        setShownSelectedRatingOption("✔️Yes, ❌No");
        break;
      case "✔️Agree, ❌Disagree":
        setSelectedRatingOption("agreeDisagree");
        setShownSelectedRatingOption("✔️Agree, ❌Disagree");
        break;
      case "⭐️ ... ⭐️⭐️⭐️⭐️⭐️":
        setSelectedRatingOption("5Stars");
        setShownSelectedRatingOption("⭐️ ... ⭐️⭐️⭐️⭐️⭐️");
        break;
      case "😞Terrible, 😔Bad, 😐Average, 🙂Good, 😃Excellent":
        setSelectedRatingOption("quality");
        setShownSelectedRatingOption(
          "😞Terrible, 😔Bad, 😐Average, 🙂Good, 😃Excellent"
        );
        break;
      case "🐢Very Slow, 🐇Slow, 🦊Average, 🐆Fast, 🦅Very Fast":
        setSelectedRatingOption("responsiveness");
        setShownSelectedRatingOption(
          "🐢Very Slow, 🐇Slow, 🦊Average, 🐆Fast, 🦅Very Fast"
        );
        break;
      default:
        console.log("SWERR!");
    }
  }
  const addCriteria = () => {
    return (
      <Box>
        <>
          <Typography
            fontSize="14px"
            marginLeft="4px"
            marginTop="0.5em"
            color="grey"
            fontStyle="italic"
            marginBottom="0.25em"
          >
            Add at least one evaluation criterion:{" "}
          </Typography>
          <Stack spacing="6px" direction="row">
            <TextField
              placeholder="e.g., For Media: Credibility, Balanced Coverage, Independent, Well-Researched..."
              label={`Criterion:`}
              style={{ fontWeight: "bold", marginBottom: '.5em', marginLeft: "2px", width: "400px" }}
              textAlign="justify"
              inputProps={{ maxLength: 100, wrap: "hard" }}
              variant="outlined"
              multiline={true}
              value={evalCriteriaName}
              onChange={(event) => setEvalCriteriaName(event.target.value)}
              onKeyPress={handleEvalCriteriaNameChange}
            />
            <Autocomplete
              value={shownSelectedRatingOption}
              variant="filled"
              style={{ marginLeft: "3px", width: "350px" }}
              color="default"
              options={ratingOptions}
              onChange={handleRatingTypeChange}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Rating Type"
                  variant="filled"
                />
              )}
            />
            {/* </Stack>
          <Stack spacing={2} marginTop=".5em" direction="row"> */}
            <Button
              variant="contained"
              backgroundcolor="#333"
              // endIcon={<SendIcon backgroundColor="grey" />}
              style={{
                border: "none",
                width: "100px",
                height: "3em",
                marginLeft: "3px",
                // marginTop: ".5em",
                marginBottom: ".25em",
                borderRadius: 28,
                color: "black",
                backgroundColor: "#D1D1D1",
              }}
              onClick={handleAddCriteria}
            >
              Save/Next
            </Button>
            <Button
              variant="contained"
              backgroundcolor="#333"
              // endIcon={<SendIcon backgroundColor="grey" />}
              style={{
                border: "none",
                width: "100px",
                height: "3em",
                marginLeft: "8px",
                // marginTop: ".5em",
                marginBottom: ".25em",
                borderRadius: 28,
                color: "black",
                backgroundColor: "#D1D1D1",
              }}
              onClick={handleAddCriteriaDone}
            >
              DONE
            </Button>
          </Stack>
          {/* </Stack> */}
        </>
      </Box>
    );
  };

  const addTarget = () => {
    return (
      <div>
        <Stack
          spacing={2}
          sx={{
            display: "flex",
            marginBottom: "1em",
            justifyContent: "flex-start",
          }}
        >
          <TextField
            label="Enter Target"
            placeholder="e.g., CNN, BBC, New York Times, Al Jazeera, facebook,..."
            style={{ marginLeft: "3px", width: "400px" }}
            textAlign="justify"
            inputProps={{ maxlength: 300, wrap: "hard" }}
            variant="filled"
            multiline="true"
            value={evalTargetName}
            onChange={(event) => setEvalTargetName(event.target.value)}
            onKeyPress={handleEvalTargetNameChange}
            helperText={`${evalTargetName.length}/300`}
          />
          <Button
            variant="contained"
            backgroundcolor="#333"
            // endIcon={<SendIcon backgroundColor="grey" />}
            style={{
              border: "none",
              width: "250px",
              marginTop: "1em",
              marginLeft: "2px",
              borderRadius: 28,
              color: "black",
              backgroundColor: "#D1D1D1",
            }}
            onClick={handleAddTarget}
          >
            Save/Next
          </Button>
          <Button
            variant="contained"
            backgroundcolor="#333"
            // endIcon={<SendIcon backgroundColor="grey" />}
            onClick={() => {
              handleSubmit();
            }}
            style={{
              border: "none",
              width: "250px",
              marginTop: "1em",
              marginLeft: "2px",
              borderRadius: 28,
              color: "black",
              backgroundColor: "#D1D1D1",
            }}
          >
            DONE
          </Button>
        </Stack>
      </div>
    );
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box marginTop={3} marginLeft="4px">
          <Grid container spacing={3} direction="row">
            <Grid item xs={6}>
              <Card
                elevation={10}
                sx={{ width: "1080px", marginRight: "20px" }}
              >
                <Stack>
                  <Autocomplete
                    value={selectedCategory}
                    variant="filled"
                    style={{
                      marginLeft: "3px",
                      marginTop: "1em",
                      marginBottom: "0.5em",
                      width: 300,
                    }}
                    color="default"
                    options={questionCategory}
                    onChange={handleCategoryChange}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Category" variant="filled" />
                    )}
                  />
                  <Stack direction='row'>
                    <TextField
                      label="Enter Title"
                      placeholder="e.g., Rate Big Media"
                      style={{ marginLeft: '3px', marginTop: '.5em', width: "750px" }}
                      textAlign="justify"
                      inputProps={{ maxlength: 300, wrap: "hard" }}
                      variant="filled"
                      multiline="true"
                      value={evalTitle}
                      onChange={(event) => setEvalTitle(event.target.value)}
                      onKeyDown={handleEvalTitleChange}
                      helperText={`${evalTitle.length}/300`}
                    />

                    <DatePicker
                      label="Closing Date"
                      onChange={(value) => handleExpiryDateChange(value)}
                      sx={{ marginTop: '.5em', marginLeft: '10px', width: '200px' }}
                      clearText="Clear"
                      cancelText="Cancel"
                      todayText="Today"
                      showTodayButton />
                  </Stack>
                </Stack>
                {/* <Button
                  variant="contained"
                  backgroundcolor="#333"
                  border="none"
                  sx={{ color: "black", backgroundColor: "#D1D1D1", borderRadius: 28, marginTop: '1em', marginLeft: '10px', width: '100px' }}

                  opacity={isButtonDisabled ? 0.25 : 1}

                  disabled={isButtonDisabled}
                // onClick={disableTitleChange}
                >
                  Next
                </Button> */}
                {showCriteriaEntry && addCriteria()}
                {showTargetEntry && addTarget()}
              </Card>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    </>
  )
}

export default NewRating;
