import React, { useState, useEffect } from "react";

import {
    Card,
    Alert,
    Box,
    Popover,
    Tooltip,
    Typography,
    TextField,
    Grid,
    Stack,
    Autocomplete,
    IconButton,
    Button,
    FormControl,
    Paper,
    TableContainer,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Rating,
    CardContent,
} from "@mui/material";

import DBOpenPollResults from "./DBOpenPollResults";
import DBClosedPollResults from "./DBClosedPollResults";
import DBOpenRateResults from "./DBOpenRateResults";
import DBClosedRateResults from "./DBClosedRateResults";

function PollResults(props) {
    const { userId, db } = props;
    const [openPollResults, setShowOpenPollResults] = React.useState(true);
    const [openRateResults, setShowOpenRateResults] = React.useState(false);
    const [closedRateResults, setShowClosedRateResults] = React.useState(false);
    const [closedPollResults, setShowClosedPollResults] = React.useState(false);
    const button2Color = "#f5f5f5";
    const buttonSelectedColor = "#af52bf";
    const [selectedButton, setSelectedButton] = useState(1);

    const handleOpenPollResults = () => {
        setShowOpenPollResults(!openPollResults);
        setShowClosedPollResults(false);
        setShowOpenRateResults(false);
        setShowClosedRateResults(false);
        if (!(selectedButton === 1)) {
            setSelectedButton(1);
        } else {
            setSelectedButton(0);
        }
    };

    const handleOpenRateResults = () => {
        setShowOpenRateResults(!openRateResults);
        setShowOpenPollResults(false);
        setShowClosedPollResults(false);
        setShowClosedRateResults(false);
        if (!(selectedButton === 3)) {
            setSelectedButton(3);
        } else {
            setSelectedButton(0);
        }
    };
    const handleClosedPollResults = () => {
        setShowClosedPollResults(!closedPollResults);
        setShowOpenPollResults(false);
        setShowOpenRateResults(false);
        setShowClosedRateResults(false);

        if (!(selectedButton === 2)) {
            setSelectedButton(2);
        } else {
            setSelectedButton(0);
        }
    };

    const handleClosedRateResults = () => {
        setShowClosedRateResults(!closedRateResults);
        setShowOpenPollResults(false);
        setShowClosedPollResults(false);
        setShowOpenRateResults(false);
        if (!(selectedButton === 4)) {

            setSelectedButton(4);
        } else {
            setSelectedButton(0);
        }
    };

    return (
        <>
            <Box marginTop={3} marginLeft="4px">
                <Stack spacing={4} direction="row">
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleOpenPollResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "2px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 1 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 1 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            // backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        OPEN POLLS
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleClosedPollResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "15px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 2 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 2 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        CLOSED POLLS
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleOpenRateResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "15px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 3 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 3 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        OPEN RATINGS
                    </Button>
                    <Button
                        variant="contained"
                        backgroundcolor="#333"
                        onClick={() => {
                            handleClosedRateResults();
                        }}
                        style={{
                            border: "none",
                            width: "165px",
                            height: "60px",
                            marginLeft: "15px",
                            marginTop: "6px",
                            marginBottom: "4px",
                            borderRadius: 10,
                            fontWeight: "bold",
                            // color: "white",
                            backgroundColor:
                                selectedButton === 4 ? buttonSelectedColor : button2Color,
                            color: selectedButton === 4 ? "white" : "grey",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                            textTransform: "none",
                            //backgroundColor: "#5B76D7",
                            // backgroundColor: "#D1D1D1",
                        }}
                    >
                        CLOSED RATINGS
                    </Button>
                </Stack>
                {openPollResults && <DBOpenPollResults userId={userId} db={db} />}
                {openRateResults && <DBOpenRateResults userId={userId} db={db} />}
                {closedPollResults && <DBClosedPollResults userId={userId} db={db} />}
                {closedRateResults && <DBClosedRateResults userId={userId} db={db} />}
                {/* </Stack> */}
            </Box>
        </>
    );
}

export default PollResults;
