import React, { useState, useEffect } from "react";
import {
    Card,
} from "@mui/material";
import Login from "./Login";
function LoginPage({ onLogin, userId, onLogout }) {
    // const { userId, db } = props;
    return (
        <>
            <Card
                elevation={10}
                sx={{
                    width: "320px",
                    marginTop: ".75em",
                    marginBottom: ".5em",
                    marginLeft: "1px",
                    marginRight: "4px",
                }}
            >
                <>
                    <Login onLogin={onLogin} userId={userId} />
                </>
            </Card>

        </>
    )
}

export default LoginPage