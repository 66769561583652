import React from "react";
import OptionsCorp from "./OptionsCorp";
import {
    Card,
} from "@mui/material";

function CorpPage({ userId, onLogin }) {
    return (
        <OptionsCorp userId={userId} onLogin={onLogin} />
    )
}

export default CorpPage